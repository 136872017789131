import { CandidateApplication } from "../candidate-application-preview/candidate-application-preview.component"
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { useNavigate, useParams } from "react-router-dom";

import { CandidateDetailsContext } from "../context/candidateDetailsContext";

import './viewCandidateApplication.styles.scss';
import { GetCandidateDocuments } from "../Utilities/firebase/firebase.utils";


const ViewCandidateApplication = () => {
    const { candidateDetailsForApplication } = useContext(CandidateDetailsContext)

    const navigate = useNavigate();

    let componentRef = useRef();

    const [candidateDetails, setCandidateDetails] = useState(candidateDetailsForApplication);


    const { docID } = useParams()

    useEffect(() => {
        const candidateData = async () => {
            GetCandidateDocuments(docID, setCandidateDetails)
        }
        candidateData()
    }, [docID])



    return (
        <div className="application-preview-wrapper" style={{}}>
            <button className="back-btn" onClick={() => navigate('../')}>Back</button>

            <div>
                <h1 style={{ textAlign: 'center' }}>Candiate Application</h1>
            </div>


            <div className="candidate-form-preview">
                <CandidateApplication candidateDetailsForApplication={candidateDetails} ref={componentRef} />
            </div>

            <div className="candidate-application-btns">
                <ReactToPrint
                    trigger={() => <button>Download Form</button>}
                    content={() => componentRef.current}
                />


                <a href={candidateDetails.CandidateAttachments} target='_blank' rel="noreferrer" download>
                    <button>
                        Download All Attachments
                    </button>
                </a>
            </div>


        </div>
    )
}

export default ViewCandidateApplication;