import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GetCandidateDocuments, StoreDataInFirebase } from '../../Utilities/firebase/firebase.utils.js'
import TicketIssueForm from "./ticket-issue-form.component";

const defaultValue = {
    arrivalDate: '',
    arrivalTime: '',
    ticketAttachment: '',
}

const TicketIssue = ({ docID }) => {


    const [candidateDetails, setCandidateDetails] = useState();

    const [ticketDetails, setTicketDetails] = useState(defaultValue);

    const [displayForm, setDisplayForm] = useState(false);

    useEffect(() => {
        const candidateData = async () => {
            GetCandidateDocuments(docID, setCandidateDetails)
        }
        candidateData()


    }, [docID, displayForm])



    const formDisplayHandler = () => {
        setDisplayForm(true)
    }

    const submitHandler = async (e) => {
        e.preventDefault();

        setTicketDetails({ ...defaultValue })

        await StoreDataInFirebase(docID, ticketDetails, "ticketAttachment");

        alert('Ticket Details Updated, Please Refresh the page to get Updated Data')

        setDisplayForm(false);

    }

    const changeHandler = (e) => {

        if (e.target.type === 'file') {
            setTicketDetails({ ...ticketDetails, [e.target.name]: e.target.files[0] })
        }
        else {
            setTicketDetails({ ...ticketDetails, [e.target.name]: e.target.value })
        }

    }

    return (

        candidateDetails &&

        <div className="onboard-sec-wrapper">
            <Link to='../'>
                <button className='onboard-back-btn'>
                    Back to Dashboard
                </button>
            </Link>

            <h1 style={{ textAlign: 'center' }}>Ticket Details</h1>

            <div className='onboard-sec'>

                {
                    displayForm && (
                        <div className="onboard-form-wrapper ">
                            <TicketIssueForm submitHandler={submitHandler} changeHandler={changeHandler} />
                        </div>
                    )
                }

                {
                    !displayForm && (
                        <div>
                            <p>
                                Name: <span>{candidateDetails.name}</span>
                            </p>
                            <p>
                                Passport No: <span>{candidateDetails.passportNo}</span>
                            </p>
                            <p>
                                Arrival Date: <span>{candidateDetails.arrivalDate}</span>
                            </p>

                            <p>
                                Arrival Time:  <span>{candidateDetails.arrivalTime}</span>
                            </p>

                            <p>

                                <a href={candidateDetails.ticketAttachment} download target="_blank" rel="noreferrer">
                                    <button className="onboard-download-btn" >
                                        Download Ticket Attachment
                                    </button>
                                </a>

                            </p>

                        </div>
                    )
                }

            </div>

            {
                !displayForm && (
                    <div style={{ textAlign: 'center' }}>

                        <Link to={`../${docID}/visa-issue`}>
                            <button className="onboard-general-btn">
                                Back
                            </button>
                        </Link>

                        <button className="onboard-general-btn" style={{ margin: '0 40px' }} onClick={formDisplayHandler}>
                            Edit Details
                        </button>

                        {
                            candidateDetails.arrivalDate ?

                                <button className='onboard-general-btn'>
                                    onboard
                                </button>
                                :
                                <button className='onboard-general-btn change-cursor-type'
                                    onClick={() => {
                                        alert('Update the ticket Details')
                                    }}
                                >
                                    onboard
                                </button>
                        }

                    </div>
                )
            }
        </div >
    )
}


export default TicketIssue;