

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


import VisaForm from './visa-issue-form.component';

import { GetCandidateDocuments, StoreDataInFirebase } from '../../Utilities/firebase/firebase.utils.js'

const defaultValue = {
    visaNo: '',
    visaIssuedDate: '',
    visaExpiryDate: '',
    visaAttachment: ''
}

const VisaIssue = ({ docID }) => {



    const [candidateDetails, setCandidateDetails] = useState();

    const [visaDetails, setVisaDetails] = useState(defaultValue);

    const [displayForm, setDisplayForm] = useState(false);

    const nationalities = ['Nepal', 'India', 'Pakistan', 'Bangladesh', 'Philippines', 'Sri Lanka']


    useEffect(() => {

        const candidateData = async () => {
            GetCandidateDocuments(docID, setCandidateDetails)
        }


        candidateData()

    }, [docID])


    const formDisplayHandler = () => {
        setDisplayForm(true)
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        await StoreDataInFirebase(docID, visaDetails, "visaAttachment");
        alert('Visa Details Updated')
        setDisplayForm(false);
    }

    const changeHandler = (e) => {

        if (e.target.type === 'file') {
            defaultValue[e.target.name] = e.target.files[0]
        }
        else {
            defaultValue[e.target.name] = e.target.value
        }

        setVisaDetails({ ...defaultValue })
    }

    return (

        candidateDetails &&

        <div className="onboard-sec-wrapper">

            <Link to='../'>
                <button className='onboard-back-btn'>
                    Back to Dashboard
                </button>
            </Link>

            <h1 style={{ textAlign: 'center' }}>Visa Details</h1>

            <div className='onboard-sec'>

                {
                    displayForm && (
                        <div className="onboard-form-wrapper ">
                            <VisaForm submitHandler={submitHandler} changeHandler={changeHandler} />
                        </div>
                    )
                }

                {
                    !displayForm && (
                        <div>
                            <p>
                                Name: <span>{candidateDetails.name}</span>
                            </p>
                            <p>
                                Passport No: <span>{candidateDetails.passportNo}</span>
                            </p>
                            <p>
                                Visa NO: <span>{candidateDetails.visaNo}</span>
                            </p>

                            <p>
                                Visa Issued Date:  <span>{candidateDetails.visaIssuedDate}</span>
                            </p>

                            <p>
                                Visa Expiry Date:  <span>{candidateDetails.visaExpiryDate}</span>
                            </p>

                            <p>
                                <a href={candidateDetails.visaAttachment} download target="_blank" rel="noreferrer">
                                    <button className="onboard-download-btn" >
                                        Download Visa Attachment
                                    </button>
                                </a>

                            </p>

                        </div>
                    )
                }

            </div>

            {
                !displayForm && (
                    <div style={{ textAlign: 'center' }}>

                        {nationalities.includes(candidateDetails.nationality) ?
                            <Link to={`../${docID}/qvc-booking`}>
                                <button className="onboard-general-btn" style={{ marginRight: '40px' }}>
                                    Back
                                </button>
                            </Link>

                            : ''}

                        <button className="onboard-general-btn" style={{ marginRight: '40px' }} onClick={formDisplayHandler}>
                            Edit Details
                        </button>


                        {
                            candidateDetails.visaNo ?
                                <Link to={`../${docID}/ticket-issue`}>
                                    <button className='onboard-general-btn'>
                                        Ticket Issue
                                    </button>
                                </Link>

                                :
                                <button className='onboard-general-btn change-cursor-type'
                                    onClick={() => {
                                        alert('Update the Visa Issued Details')
                                    }}
                                >
                                    Ticket Issue
                                </button>
                        }

                    </div>
                )
            }
        </div>
    )
}


export default VisaIssue;