import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateAdminLogIn } from "../Utilities/firebase/firebase.utils";
import "./admin-log-in.style.scss";
import logo from "../../assets/logo.png";


import { useContext } from "react";
import { userDetailsContext } from "../context/userDetailsContext";


const AdminLoginPanel = () => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const [showPassword, setShowpassword] = useState(false);

    const { setIsLoggedIn } = useContext(userDetailsContext)

    const navigate = useNavigate();



    const handleSubmit = async (e) => {
        e.preventDefault();
        try {

            await ValidateAdminLogIn(email, password);

            alert("logged in")

            localStorage.setItem('isLoggedIn', true)
            setIsLoggedIn(true);

            document.querySelectorAll('.admin-login-inputs').forEach((input) => {
                input.value = '';
            })

            navigate('/dashboard');

        } catch (error) {
            if (error.code === "auth/user-not-found" || error.code === "auth/wrong-password") {
                alert("Username or Password Incorect")
            }
            else (
                console.error(error.message)
            )
        }
    }

    return (

        <div className="log-in-page">
            <div className="log-in-content">
                <img src={logo} alt="Al sraiya security services" />
                <h1>Admin Login Portal</h1>
                <form onSubmit={handleSubmit}>
                    <div className="log-in-input-wrapper">
                        <label>Email</label>
                        <input type="email" className="admin-login-inputs" onChange={e => setEmail(e.target.value)} required />
                    </div>

                    <div className="log-in-input-wrapper">
                        <label>Password</label>
                        <input type={showPassword ? "text" : "password"} className="admin-login-inputs" onChange={e => setPassword(e.target.value)} required />
                    </div>

                    <br />
                    <div style={{ display: "flex", justifyContent: 'center' }}>
                        <input type="checkbox" onClick={() => setShowpassword(!showPassword)} id="showPassword" style={{ cursor: 'pointer' }} />
                        <label htmlFor='showPassword' style={{ cursor: 'pointer' }}>Show Password</label>
                    </div>

                    <div className="log-in-submit-btn">
                        <button type="submit">Log In</button>
                    </div>
                </form>
            </div>


        </div>

    )
}

export default AdminLoginPanel;

