// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword } from 'firebase/auth';
import {
    getFirestore,
    collection,
    addDoc,
    getDocs,
    doc, updateDoc, deleteDoc,
    setDoc, getDoc

} from 'firebase/firestore';

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDi9ojkjZuYG0s_Xh8kC2NsCbs2mvuqug0",
    authDomain: "candidate-application-form.firebaseapp.com",
    projectId: "candidate-application-form",
    storageBucket: "candidate-application-form.appspot.com",
    messagingSenderId: "256776741619",
    appId: "1:256776741619:web:83277aaa2221bbf5198d86"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth()

const db = getFirestore();

export const createUserDocumentFromAuth = async (userAuth, additionalInformation = {}) => {

    if (!userAuth) return;
    //seting up doc name for database
    const userDocRef = doc(db, 'users', userAuth.uid);

    const userSnapshot = await getDoc(userDocRef)

    if (!userSnapshot.exists()) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();

        try {
            await setDoc(userDocRef, {
                displayName,
                email,
                createdAt,
                ...additionalInformation
            });
        } catch (error) {
            console.error('error creating the user', error.message)
        }
    }
}

export const createAuthUserWithEmailAndPassword = async (email, password) => {

    if (!email || !password) return;

    return await createUserWithEmailAndPassword(auth, email, password);

}


export const GetUserDocumentFromAuth = async (user, setUserDataInContext) => {
    if (user != null) {
        const userDocRef = doc(db, 'users', user.uid);
        const userSnapshot = await getDoc(userDocRef)
        setUserDataInContext(userSnapshot.data());
    }

}

export const onAuthStateChangedListiner = (callback) => onAuthStateChanged(auth, callback)


export const ValidateAdminLogIn = async (email, password) => {

    if (!email || !password) return;

    return await signInWithEmailAndPassword(auth, email, password);
}

export const signoutUser = async () => {
    return await signOut(auth);
}

//writing data
export const storeCandidateDetails = async (candidateDetails) => {
    try {
        await addDoc(collection(db, "candidate-application-forms"), candidateDetails);
    } catch (e) {
        console.error("Error adding document: ", e);
    }

}

//Reading data from database
export const GetDataFromDatabase = async (getCandidateData) => {

    const candidateData = []

    const querySnapshot = await getDocs(collection(db, "candidate-application-forms"));

    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const documentData = { docID: doc.id, ...doc.data() };
        candidateData.push(documentData);
    });

    getCandidateData(candidateData)
}


//Reading user data from database
export const GetUserListFromDatabase = async (getUserList) => {

    const userList = []

    const querySnapshot = await getDocs(collection(db, "users"));

    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const documentData = { docID: doc.id, ...doc.data() };

        if (documentData.role !== 'admin') {
            userList.push(documentData);
        }
    });

    getUserList(userList);

}

//updatating the data
export const updateCandidateStatusInDatabase = async (docID, operationType) => {

    const candidateDetails = doc(db, "candidate-application-forms", docID);

    if (operationType === 'select') {
        await updateDoc(candidateDetails, {
            isSelected: true
        });
    }
    else if (operationType === 'reject') {
        await updateDoc(candidateDetails, {
            isSelected: false
        });
    }
}



//updatating the data
export const updateCandidateDetails = async (docID, details) => {

    const candidateDetails = doc(db, "candidate-application-forms", docID);


    await updateDoc(candidateDetails, {
        ...details
    });

}

export const updateUserRoleInDatabase = async (docID) => {

    const user = doc(db, "users", docID);

    await updateDoc(user, {
        role: 'admin'
    });

}


export const deleteDataFromDatabase = async (dataID, dataCollectionName) => {
    await deleteDoc(doc(db, dataCollectionName, dataID));
}


// Create a root reference
const storage = getStorage();


export const StoreDataInFirebase = async (docID, details, keyName) => {

    try {
        const attachmentRef = ref(storage, Date() + details[keyName].name);


        await uploadBytes(attachmentRef, details[keyName]).then((snapshot) => {
            getDownloadURL(attachmentRef)
                .then((url) => {
                    details[keyName] = url + " ";
                    updateCandidateDetails(docID, details)
                })
        })

    } catch (error) {
        console.error(error.message)
    }
}

export const GetCandidateDocuments = async (docID, setDataInState) => {
    try {
        const docRef = doc(db, "candidate-application-forms", docID);
        const docSnap = await getDoc(docRef);
        setDataInState({ ...docSnap.data() })
    } catch (error) {
        console.log(error.message)
    }
}