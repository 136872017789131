
import { Route, Routes } from 'react-router-dom';

import AdminLoginPanel from './component/admin-log-in/admin-log-in.component';

import Dashboard from './component/dashboard/dashboard.component';

import ProtectedRoute from './component/protected-routes/protected-routes.utilities';
import ViewCandidateApplication from './component/View Candidate Application/ViewCandidateApplication.component';
import { AllUserList } from './component/dashboard/dashboard-all-user-list/dashboard-all-user-list.component';
import CreateAdminAccountForm from './component/Create-Admin-component/create-admin.component';

import OnboardingProcess from './component/Onboard-Components/Onboard-process/Onboard-Processes.component';


function App() {

  return (

    <Routes>

      <Route path='/' element={<AdminLoginPanel />} />

      <Route element={<ProtectedRoute />}>
      <Route path='dashboard'>
          <Route element={<Dashboard />} index />
          <Route path=':docID' element={<ViewCandidateApplication />} />
          <Route path='users' element={<AllUserList />} />
          <Route path='create-admin' element={<CreateAdminAccountForm />} />
          <Route path=':docID/:onboardProcess' element={<OnboardingProcess />} />
        </Route>
      </Route>

    </Routes>


  );
}

export default App;
