import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import './qvc-booking.syles.scss';

import QVCForm from './qvc-booking-form.component';

import { GetCandidateDocuments, StoreDataInFirebase } from '../../Utilities/firebase/firebase.utils.js'

const defaultValue = {
    QVCDate: '',
    QVCTime: '',
    QVCBookingAttachment: ''
}

const QVCBooking = ({ docID }) => {


    const [candidateDetails, setCandidateDetails] = useState();

    const [QVCDetails, setQVCDetails] = useState(defaultValue);

    const [fileSrc, setFileSrc] = useState();

    const [displayForm, setDisplayForm] = useState(false);


    const setDataInState = (data) => {
        setCandidateDetails({ ...data })
    }


    useEffect(() => {

        const candidateData = async () => {
            GetCandidateDocuments(docID, setDataInState)
        }

        candidateData()

    }, [docID, displayForm])


    const formDisplayHandler = () => {
        setDisplayForm(true)
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        await StoreDataInFirebase(docID, QVCDetails, "QVCBookingAttachment");
        alert('Updated QVC Booking Details, Please Refresh the page to get Updated Data')
        setDisplayForm(false);
    }

    const changeHandler = (e) => {

        if (e.target.type === 'file') {
            defaultValue[e.target.name] = e.target.files[0]
            setFileSrc(URL.createObjectURL(e.target.files[0]))
        }
        else {
            defaultValue[e.target.name] = e.target.value
        }

        setQVCDetails({ ...defaultValue })
    }

    return (
        candidateDetails &&
        <div className="qvc-booking-sec-wrapper onboard-sec-wrapper">

            <Link to='../'>
                <button className='onboard-back-btn'>
                    Back to Dashboard
                </button>
            </Link>

            <h1 style={{ textAlign: 'center' }}>QVC Booking</h1>

            <div className='qvc-booking-sec onboard-sec'>

                {
                    displayForm && (
                        <div className="qvc-booking-form-wrapper onboard-form-wrapper ">
                            <QVCForm submitHandler={submitHandler} changeHandler={changeHandler} />
                        </div>
                    )
                }

                {
                    !displayForm && (
                        <div>
                            <p>
                                Name: <span>{candidateDetails.name}</span>
                            </p>
                            <p>
                                Passport No: <span>{candidateDetails.passportNo}</span>
                            </p>
                            <p>
                                QVC Date: <span>{candidateDetails.QVCDate}</span>
                            </p>

                            <p>
                                QVC Time:  <span>{candidateDetails.QVCTime}</span>
                            </p>

                            <p>
                                {
                                    candidateDetails.QVCBookingAttachment ?

                                        <a href={candidateDetails.QVCBookingAttachment} download target="_blank" rel="noreferrer">
                                            <button className="onboard-download-btn" >
                                                Download QVC Booking–Attachment
                                            </button>

                                        </a>

                                        :

                                        <a href={fileSrc} download>
                                            <button className="onboard-download-btn" >
                                                Download QVC Booking–Attachment
                                            </button>

                                        </a>

                                }
                            </p>
                        </div>
                    )
                }

            </div>

            {
                !displayForm && (
                    <div style={{ textAlign: 'center' }}>

                        <button className="onboard-general-btn" style={{ marginRight: '40px' }} onClick={formDisplayHandler}>
                            Edit Details
                        </button>

                        {
                            candidateDetails.QVCDate ?

                                <Link to={`../${docID}/visa-issue`}>
                                    <button className='onboard-general-btn'>
                                        Visa Issue
                                    </button>
                                </Link>
                                :
                                <button className={`onboard-general-btn change-cursor-type'}`}
                                    onClick={() => {
                                        alert('Update the QVC Booking Details')
                                    }}
                                >
                                    Visa Issue
                                </button>
                        }

                    </div>
                )
            }
        </div>
    )
}

export default QVCBooking;