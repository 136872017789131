
const TicketIssueForm = ({ submitHandler, changeHandler }) => {

    return (
        <form className="qvc-booking-form onboard-form" onSubmit={submitHandler}>


            <div className="onboard-form-field">
                <label>
                    Arrival Date:
                </label>

                <input type="date" name="arrivalDate" onChange={changeHandler} required />
            </div>

            <div className="onboard-form-field">
                <label>
                    Arrival Time:
                </label>
                <input type="time" name="arrivalTime" onChange={changeHandler} required />
            </div>

            <div className="onboard-form-field">
                <label>
                    Ticket – Attachment
                </label>

                <input type='file' name="ticketAttachment" onChange={changeHandler} accept="image/png, image/jpeg, application/pdf" required />
            </div>

            <div className="submit-btn">
                <button className="onboard-general-btn" type="submit">Update Details</button>
            </div>
        </form>
    )
}

export default TicketIssueForm;