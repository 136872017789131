import { useContext } from "react";
import { userDetailsContext } from "../../context/userDetailsContext";


const HeaderDashboard = ({ onSearchHandeler, placeholder }) => {

    const { userData } = useContext(userDetailsContext)

    return (
        <div className="header-dashboard">
            <div>
                <h2 style={{ color: '#000070' }}>
                    Hello {userData.displayName} 👋
                </h2>
            </div>
            <div className="dashboard-search-box">
                <div id="search-icon">
                    <i className="fa-solid fa-magnifying-glass" />
                </div>
                <div>
                    <input type='search' placeholder={placeholder}
                        onFocus={() => document.querySelector('#search-icon').style.display = 'none'}
                        onBlur={() => document.querySelector('#search-icon').style.display = 'block'}
                        onChange={onSearchHandeler}
                    />
                </div>
            </div>
        </div>)
}


export default HeaderDashboard;