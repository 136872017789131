import { createContext, useEffect, useState } from "react";
import { GetUserDocumentFromAuth, onAuthStateChangedListiner } from "../Utilities/firebase/firebase.utils";

export const userDetailsContext = createContext(null);

export const UserDetailsContextProvider = ({ children }) => {

    const [userData, setUserData] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(null);


    useEffect(() => {
        const unSubscribe = onAuthStateChangedListiner((user) => {

            GetUserDocumentFromAuth(user, setUserData);
        })


        return unSubscribe;
    }, [])

    const value = { userData, isLoggedIn, setIsLoggedIn }

    return <userDetailsContext.Provider value={value}>{children}</userDetailsContext.Provider>;
}