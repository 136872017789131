import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createAuthUserWithEmailAndPassword, createUserDocumentFromAuth } from '../Utilities/firebase/firebase.utils';
import './create-admin.styles.scss';

const defaultFormFields = {
    displayName: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: 'Sub Admin'
}



const CreateAdminAccountForm = () => {

    const [formFields, setFormFields] = useState(defaultFormFields)
    const { displayName, email, password, confirmPassword, role } = formFields;


    const navigate = useNavigate();

    const changeHandeler = (e) => {
        const { name, value } = e.target;
        setFormFields({ ...formFields, [name]: value })
    }

    const submitFormHandeler = async (e) => {

        e.preventDefault()

        if (password !== confirmPassword) {
            alert('Passwords do not match')
            return;
        }

        try {
            const { user } = await createAuthUserWithEmailAndPassword(email, password);

            await createUserDocumentFromAuth(user, { displayName, role })

            alert('Account Created');

            window.location.reload();


        } catch (err) {
            if (err.code === 'auth/email-already-in-use') {
                alert("Cannot Create user, Email already exits")
            } else {
                console.log(err);
            }
        }

    }

    return (
        <>
            <div className='create-admin-account-form-btn'>
                <button onClick={() => {
                    navigate('../');
                    if (localStorage.getItem('isCandidatesListDisplayed') === 'false') {
                        localStorage.removeItem('isCandidatesListDisplayed')
                    }
                }}>Back</button>
            </div>

            <div className="create-admin-account-form-wrapper">
                <form onSubmit={submitFormHandeler}>
                    <div className="create-admin-account-form">
                        <label>Name </label>
                        <input type='text' name="displayName" required onChange={changeHandeler} />
                    </div>

                    <div className="create-admin-account-form">
                        <label>Email </label>
                        <input type='email' name="email" required onChange={changeHandeler} />
                    </div>

                    <div className="create-admin-account-form">
                        <label>Password </label>
                        <input type='password' name="password" required onChange={changeHandeler} />
                    </div>

                    <div className="create-admin-account-form">
                        <label>Confirm Password </label>
                        <input type='password' name="confirmPassword" required onChange={changeHandeler} />
                    </div>

                    <div className="create-admin-account-form">
                        <label>Role</label>
                        <select name="role" required onChange={changeHandeler} value={role}>
                            <option value="">Please Select</option>
                            <option value="Admin">Admin</option>
                            <option value="Interviewer">Interviewer</option>
                            <option value="Recruitment Cordinator">Recruitment Cordinator</option>
                            <option value="HR Manager">HR Manager</option>
                        </select>
                    </div>

                    <div className='create-admin-account-btn'>
                        <button type='submit'>Create</button>
                    </div>
                </form>
            </div>
        </>
    )
}
export default CreateAdminAccountForm;