import { useContext, useEffect, useState } from "react";
import { deleteDataFromDatabase, GetDataFromDatabase } from "../Utilities/firebase/firebase.utils";
import { updateCandidateStatusInDatabase } from "../Utilities/firebase/firebase.utils";


import { Outlet } from "react-router-dom";

import { CandidateDetailsContext } from "../context/candidateDetailsContext";
import SideBar from "./dashboard-sidebar-component/sidebar.component";

import './dashboard.style.scss';
import HeaderDashboard from "./dashboard-header-component/headerDashboard.component";
import CandidateList from "./dashboard-candidate-list/candidateList.component";
import { userDetailsContext } from "../context/userDetailsContext";


const Dashboard = () => {

    const { setCandidateDetailsForApplication, setTotalCandidates, setTotalSelectedCandidates } = useContext(CandidateDetailsContext)

    const { userData } = useContext(userDetailsContext)

    //This state is used to store the data fetch from the database
    const [candidatesData, setCandidatesData] = useState([]);

    //This state is used to store the data which should be rendering
    const [renderCandidateList, setRenderCandidateList] = useState([]);
    const [searchField, setSearchField] = useState('');

    //This state is used to fetch updated data
    const [getUpdatedData, setGetUpdatedData] = useState(false);

    useEffect(() => {

        const getCandidateData = (dataFromDatabase) => {
            setCandidatesData(dataFromDatabase);
            setRenderCandidateList(dataFromDatabase);
            setTotalCandidates(dataFromDatabase.length)
        }


        if (localStorage.getItem('isLoggedIn') && JSON.stringify(userData) !== '{}') {
            GetDataFromDatabase(getCandidateData)
        }

        // GetDataFromDataFromDatabase(getCandidateData);
    }, [getUpdatedData, setTotalCandidates, userData])


    useEffect(() => {
        const filteredCandidates = candidatesData.filter((candidate) => {
            if (candidate.name) {
                return candidate.name.toLowerCase().startsWith(searchField);
            }
            return '';
        })

        setRenderCandidateList([...filteredCandidates])


        const selectedCandidates = candidatesData.filter((candidate) => {
            return candidate.isSelected;
        })

        setTotalSelectedCandidates(selectedCandidates.length)


    }, [searchField, candidatesData, setTotalSelectedCandidates])

    const renderAllCandidates = () => {
        setRenderCandidateList(candidatesData)
    }

    const onSearchHandeler = (event) => {
        const searchFieldString = event.target.value.toLowerCase();
        setSearchField(searchFieldString);
    }

    const updateCandidateStatus = (index, operationType) => {
        updateCandidateStatusInDatabase(renderCandidateList[index].docID, operationType)
        setGetUpdatedData(!getUpdatedData);
    }


    const deleteCandidateDetails = async (index) => {
        await deleteDataFromDatabase(renderCandidateList[index].docID, 'candidate-application-forms')
        setGetUpdatedData(!getUpdatedData);
    }

    const updateContext = (index) => {
        setCandidateDetailsForApplication(renderCandidateList[index])
    }

    const filterCandidates = (filterType) => {

        const candidates = candidatesData.filter((candidate) => {
            if (filterType === 'selected') {
                return candidate.isSelected;
            }

            return !candidate.isSelected;
        })

        setRenderCandidateList([...candidates]);
    }


    return (
        <div>

            <Outlet />

            {
                JSON.stringify(userData) !== '{}' &&

                <>
                    <SideBar />

                    <div className="dashboard-center">

                        <HeaderDashboard placeholder='Search Candidates...' onSearchHandeler={onSearchHandeler} />

                        <div className="dasboard-candidate-list">

                            <h2>Candidates</h2>
                            <div className="border" />
                            <div className="candidte-filter-btns">
                                <button onClick={renderAllCandidates}>All Candidates</button>
                                <button style={{ margin: '0 20px' }} onClick={() => filterCandidates('selected')}>Selected Candidates</button>
                                <button onClick={() => filterCandidates('rejected')}>Rejected Candidates</button>
                            </div>

                            <CandidateList
                                renderCandidateList={renderCandidateList}
                                updateContext={updateContext}
                                deleteCandidateDetails={deleteCandidateDetails}
                                updateCandidateStatus={updateCandidateStatus}
                            />
                        </div>


                    </div>
                </>
            }


        </div >
    )
}

export default Dashboard;