
import { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { userDetailsContext } from '../context/userDetailsContext';



const ProtectedRoute = () => {

    const { setIsLoggedIn } = useContext(userDetailsContext)

    useEffect(() => {
        setIsLoggedIn(Boolean(localStorage.getItem('isLoggedIn')))
    }, [setIsLoggedIn])

    return (
        Boolean(localStorage.getItem('isLoggedIn')) ? <Outlet /> : <Navigate to="/" />
    )

};

export default ProtectedRoute;

