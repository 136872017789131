import { useParams } from "react-router-dom";
import QVCBooking from "../QVC-booking/QVC-booking.component";
import TicketIssue from "../ticket-issue/ticket-issue.compoent";
import VisaIssue from "../visa-issue/visa-issue.compoent";

const OnboardingProcess = () => {

    const { docID, onboardProcess } = useParams();

    switch (onboardProcess) {
        case 'qvc-booking':
            return <QVCBooking docID={docID} />;
        case 'visa-issue':
            return <VisaIssue docID={docID} />;
        case 'ticket-issue':
            return <TicketIssue docID={docID} />;
        default:
            return <div>No Links</div>;
    }
}

export default OnboardingProcess;