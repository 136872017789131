import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';

import { CandidateDetailsContextProvider } from './component/context/candidateDetailsContext';

import { BrowserRouter } from 'react-router-dom'
import { UserDetailsContextProvider } from './component/context/userDetailsContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserDetailsContextProvider>
        <CandidateDetailsContextProvider>
          <App />
        </CandidateDetailsContextProvider>
      </UserDetailsContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

