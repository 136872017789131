
import './sidebar.style.scss';

import logo from "../../../assets/logo.png";
import { useContext, useState } from 'react';
import { userDetailsContext } from '../../context/userDetailsContext';
import { signoutUser } from '../../Utilities/firebase/firebase.utils';
import { useNavigate } from 'react-router-dom';
import { CandidateDetailsContext } from '../../context/candidateDetailsContext';



const SideBar = () => {
    const date = new Date();
    const currentYear = date.getFullYear()

    const { userData, setIsLoggedIn } = useContext(userDetailsContext)


    const { totalCandidates, totalSelectedCandidates } = useContext(CandidateDetailsContext)

    const navigate = useNavigate();


    const handleSignOutUser = async () => {
        await signoutUser();
        setIsLoggedIn(null);
        localStorage.clear();
    }

    return (
        <div className="dashboard-sidebar">
            <header>
                <img src={logo} alt="SSS" width='150px' height='auto' />
                <h2>Al Sraiya Security Services</h2>
            </header>

            <div className='count-candidates'>
                <h4>Total Candidates: <span>{totalCandidates}</span></h4>
                <h4>Selected Candidates: <span>{totalSelectedCandidates}</span></h4>
            </div>


            {
                userData.role === 'admin' && (

                    <div style={{ marginTop: '30px' }}>
                        <div className='sidebar-btns'>
                            <button onClick={() => navigate('/dashboard/create-admin')}>
                                Create New User
                            </button>
                        </div>

                        {
                            localStorage.getItem('isCandidatesListDisplayed') ? (
                                <div className='sidebar-btns'>
                                    <button
                                        onClick={() => {
                                            navigate('../');
                                            localStorage.removeItem('isCandidatesListDisplayed')
                                        }}
                                    >
                                        View all candidates
                                    </button>
                                </div>
                            ) : (
                                <div className='sidebar-btns'>
                                    <button
                                        onClick={() => {
                                            navigate('users');
                                            localStorage.setItem('isCandidatesListDisplayed', false)
                                        }}
                                    >
                                        View All Users
                                    </button>
                                </div>
                            )
                        }
                    </div>


                )

            }

            <div className='sidebar-btns'>
                <button onClick={() => handleSignOutUser()}>Sign Out</button>

            </div>


            <div style={{ textAlign: 'center', margin: '40px 0' }}>
                Copyright © {currentYear} <br />Al Sraiaya Security Services
            </div>

        </div>
    )
}

export default SideBar;

