import { createContext, useState } from "react";

export const CandidateDetailsContext = createContext(null);

export const CandidateDetailsContextProvider = ({ children }) => {

    const [candidateDetailsForApplication, setCandidateDetailsForApplication] = useState({})
    const [totalCandidates, setTotalCandidates] = useState()
    const [totalSelectedCandidates, setTotalSelectedCandidates] = useState()

    const value = { candidateDetailsForApplication, setCandidateDetailsForApplication, totalCandidates, setTotalCandidates, totalSelectedCandidates, setTotalSelectedCandidates }

    return <CandidateDetailsContext.Provider value={value}>{children}</CandidateDetailsContext.Provider>;
}