import React from "react";

import logo from "../../assets/logo.png";

import './candidate-application-preview.styles.scss';



export const CandidateApplication = React.forwardRef(({ docID, candidateDetailsForApplication }, ref) => {
    return (
        <div ref={ref} className="candidate-application-preview">

            <div className="form-headder">

                <div>
                    <img src={logo} alt="SSS" width='150px' />
                </div>

                <div>
                    <h3>Al Sraiya Securtity Services</h3>
                </div>
            </div>

            <div className="form-purpose">
                <div>
                    Position Applied for: {candidateDetailsForApplication.positionAppliedFor}
                </div>

                <div className="candidate-form-img">
                    {
                        candidateDetailsForApplication.candidatePicRef ? <img src={candidateDetailsForApplication.candidatePicRef} alt={candidateDetailsForApplication.name} /> : <h3>Photo</h3>
                    }
                </div>
            </div>

            <div className="candidates-general-details-wrappper">

                <h2 className="form-category-heading">General Information</h2>

                <div className="candidates-general-details">
                    <div>
                        <p><span className="form-field-label">Name of Applicants:</span> {candidateDetailsForApplication.title} {candidateDetailsForApplication.name} </p>
                        <p><span className="form-field-label">Address:</span>  {candidateDetailsForApplication.address}  </p>
                        <p><span className="form-field-label">Email:</span>  {candidateDetailsForApplication.email}  </p>
                        <p><span className="form-field-label">Passport Expiry Date:</span>  {candidateDetailsForApplication.passportExpiryDate} </p>
                        <p><span className="form-field-label">Date of birth:</span>   {candidateDetailsForApplication.DOB} </p>
                        <p><span className="form-field-label">Gender:</span>  {candidateDetailsForApplication.gender}</p>
                        <p><span className="form-field-label">Height:</span>  {candidateDetailsForApplication.height} {candidateDetailsForApplication.heightUnit} </p>
                    </div>

                    <div>
                        <p><span className="form-field-label">Nationality:</span>  {candidateDetailsForApplication.nationality}</p>
                        <p><span className="form-field-label">Contact Number:</span> +{candidateDetailsForApplication.candidateContactNumberCode} {candidateDetailsForApplication.contactNumber} </p>
                        <p><span className="form-field-label">Passport No:</span>  {candidateDetailsForApplication.passportNo} </p>
                        <p><span className="form-field-label">Marital Status:</span>  {candidateDetailsForApplication.maritalStatus}</p>
                        <p><span className="form-field-label">Place of birth:</span> {candidateDetailsForApplication.placeOfBirth} </p>
                        <p><span className="form-field-label">Weight:</span>  {candidateDetailsForApplication.weight} {candidateDetailsForApplication.weightUnit}  </p>
                    </div>
                </div>

                <div className="candidate-qatar-info candidate-relative-info">
                    <div>
                        <p><span className="form-field-label">Currently present in Qatar: </span>  {candidateDetailsForApplication.currentlyInQatar} </p>
                        <p><span className="form-field-label">Qatar Driving License: </span>  {candidateDetailsForApplication.qatarDrivingLicense} </p>
                        <p><span className="form-field-label">QID NO: </span>  {candidateDetailsForApplication.QIDNO} </p>
                    </div>
                    <div>

                        <p><span className="form-field-label">Qatar Contact Number: </span>  {candidateDetailsForApplication.qatarContactNum} </p>
                        <p><span className="form-field-label">Qatar Driving License Expiry Date: </span>  {candidateDetailsForApplication.qatarDrivingLicenseExpiryDate} </p>
                        <p><span className="form-field-label">QID Expiry Date: </span>  {candidateDetailsForApplication.QIDExpiryDate} </p>

                    </div>
                </div>

                <div className="candidate-relative-info">
                    <div>
                        <p><span className="form-field-label">Next of kin name:</span>  {candidateDetailsForApplication.kinName} </p>
                        <p><span className="form-field-label">Address:</span>  {candidateDetailsForApplication.addressOfKin} </p>
                    </div>
                    <div>
                        <p><span className="form-field-label">Realtionship:</span>  {candidateDetailsForApplication.relationship}  </p>
                        <p><span className="form-field-label">Contact Number:</span> +{candidateDetailsForApplication.kinContactNumberCode}  {candidateDetailsForApplication.contactNumberOfKin} </p>
                    </div>
                </div>

            </div>

            <div style={{ marginTop: '50px' }} className="candidates-education-details-wrappper">

                <h2 className="form-category-heading">Education Details</h2>

                <h3 style={{ textDecoration: 'underline', textAlign: 'center' }}>High School</h3>

                <div className="candidates-education-details flex">
                    <div>
                        <p><span className="form-field-label">Institute Name:</span> {candidateDetailsForApplication.highSchoolInstituteName} </p>
                        <p><span className="form-field-label">Degree Name:</span> {candidateDetailsForApplication.highSchoolDegreeName} </p>
                    </div>

                    <div>
                        <p><span className="form-field-label">Institute Address:</span> {candidateDetailsForApplication.highSchoolInstituteAddress} </p>
                        <p><span className="form-field-label">Year of Completion:</span> {candidateDetailsForApplication.highSchoolDegreeYOC} </p>
                    </div>
                </div>

                <hr />

                {
                    candidateDetailsForApplication.bachelorDegreeInstituteName &&
                    <>
                        <h3 style={{ textDecoration: 'underline', textAlign: 'center' }}>Bachelor Degree</h3>

                        <div className="candidates-education-details flex">
                            <div>
                                <p><span className="form-field-label">Institute Name:</span> {candidateDetailsForApplication.bachelorDegreeInstituteName} </p>
                                <p><span className="form-field-label">Degree Name:</span> {candidateDetailsForApplication.bachelorDegreeName} </p>
                            </div>

                            <div>
                                <p><span className="form-field-label">Institute Address:</span> {candidateDetailsForApplication.bachelorDegreeInstituteAddress} </p>
                                <p><span className="form-field-label">Year of Completion:</span> {candidateDetailsForApplication.bachelorDegreeYOC} </p>
                            </div>
                        </div>

                        <hr />
                    </>

                }



                {candidateDetailsForApplication.masterDegreeInstituteName &&
                    <>
                        <h3 style={{ textDecoration: 'underline', textAlign: 'center' }}>Master Degree</h3>

                        <div className="candidates-education-details flex">
                            <div>
                                <p><span className="form-field-label">Institute Name:</span> {candidateDetailsForApplication.masterDegreeInstituteName} </p>
                                <p><span className="form-field-label">Degree Name:</span> {candidateDetailsForApplication.masterDegreeName} </p>
                            </div>

                            <div>
                                <p><span className="form-field-label">Institute Address:</span> {candidateDetailsForApplication.masterDegreeInstituteAddress} </p>
                                <p><span className="form-field-label">Year of Completion:</span> {candidateDetailsForApplication.masterDegreeYOC} </p>
                            </div>
                        </div>

                        <hr />

                    </>
                }

                {
                    candidateDetailsForApplication.otherInstituteName &&
                    <>
                        <h3 style={{ textDecoration: 'underline', textAlign: 'center' }}>Other if any</h3>

                        <div className="candidates-education-details flex">
                            <div>
                                <p><span className="form-field-label">Institute Name:</span> {candidateDetailsForApplication.otherInstituteName} </p>
                                <p><span className="form-field-label">Degree Name:</span> {candidateDetailsForApplication.otherDegreeName} </p>
                            </div>

                            <div>
                                <p><span className="form-field-label">Institute Address:</span> {candidateDetailsForApplication.otherInstituteAddress} </p>
                                <p><span className="form-field-label">Year of Completion:</span> {candidateDetailsForApplication.otherDegreeYOC} </p>
                            </div>
                        </div>
                    </>
                }




            </div>

            <br />

            <div className="candidates-language-details-wrappper">

                <h2 className="form-category-heading">Languages</h2>

                <div className="candidates-language-details">

                    <div>
                        <h3 style={{ textDecoration: 'underline' }}>English</h3>
                        <div>
                            <p><span className="form-field-label">Speaking: </span> {candidateDetailsForApplication.engSpeaking} </p>
                            <p><span className="form-field-label">Writing: </span> {candidateDetailsForApplication.engWriting} </p>
                            <p><span className="form-field-label">Reading: </span> {candidateDetailsForApplication.engReading} </p>
                        </div>
                    </div>


                    <div>
                        <h3 style={{ textDecoration: 'underline' }}>Arabic</h3>
                        <div>
                            <p><span className="form-field-label">Speaking: </span> {candidateDetailsForApplication.arabicSpeaking} </p>
                            <p><span className="form-field-label">Writing: </span> {candidateDetailsForApplication.arabicWriting} </p>
                            <p><span className="form-field-label">Reading: </span> {candidateDetailsForApplication.arabicReading} </p>
                        </div>
                    </div>


                    <div>
                        <h3 style={{ textDecoration: 'underline' }}>Other if any</h3>
                        <div>
                            <p><span className="form-field-label">Speaking: </span> {candidateDetailsForApplication.otherLangaugeSpeaking} </p>
                            <p><span className="form-field-label">Writing: </span> {candidateDetailsForApplication.otherLangaugeWriting} </p>
                            <p><span className="form-field-label">Reading: </span> {candidateDetailsForApplication.otherLangaugeReading} </p>
                        </div>
                    </div>
                </div>

            </div>

            <br />


            <div className="candidates-technical-skill-details-wrappper">

                <h2 className="form-category-heading">Technical Skills</h2>

                <div className="candidates-technical-skill-details">
                    <div>
                        <p><span className="form-field-label">Previous Security Training if any:</span> {candidateDetailsForApplication.securityKnowledge} </p>

                        <p><span className="form-field-label"> CCTV knowledge experience if any: </span> {candidateDetailsForApplication.cctvKnowledge} </p>
                    </div>
                    <div>
                        <p><span className="form-field-label">Computer Knowledge:</span> {candidateDetailsForApplication.computerKnowledge} </p>
                    </div>
                </div>
            </div>

            <div className="candidates-job-experience-details-wrappper" style={{ marginTop: '40px' }}>

                <h2 className="form-category-heading">Job Experience</h2>

                <div className="candidates-job-experience-details ">
                    <div>
                        <p><span className="form-field-label"> Last job:</span>  {candidateDetailsForApplication['1stJobName']}</p>
                        <p><span className="form-field-label">From:</span>  {candidateDetailsForApplication['1stJobDurationFrom']}</p>
                        <p><span className="form-field-label">Salary:</span>  {candidateDetailsForApplication['1stJobSalary']}</p>
                        <p><span className="form-field-label">Reason For Leaving:</span>  {candidateDetailsForApplication['1stJobLeavingReason']}</p>

                    </div>
                    <div>
                        <p><span className="form-field-label">Company Name:</span>  {candidateDetailsForApplication['1stJobCompanyName']}</p>
                        <p><span className="form-field-label">To:</span>  {candidateDetailsForApplication['1stJobDurationTo']}</p>
                        <p><span className="form-field-label">Address:</span>  {candidateDetailsForApplication['1stJobCompanyAddress']}</p>
                    </div>
                </div>

                <hr />

                {
                    candidateDetailsForApplication['2ndJobName'] &&
                    <>
                        <div className="candidates-job-experience-details">
                            <div>
                                <p><span className="form-field-label"> Last job:</span>  {candidateDetailsForApplication['2ndJobName']}</p>
                                <p><span className="form-field-label">From:</span>  {candidateDetailsForApplication['2ndJobDurationFrom']}</p>
                                <p><span className="form-field-label">Salary:</span>  {candidateDetailsForApplication['2ndJobSalary']}</p>
                                <p><span className="form-field-label">Reason For Leaving:</span>  {candidateDetailsForApplication['2ndJobLeavingReason']}</p>
                            </div>
                            <div>
                                <p><span className="form-field-label">Company Name:</span>  {candidateDetailsForApplication['2ndJobCompanyName']}</p>
                                <p><span className="form-field-label">To:</span>  {candidateDetailsForApplication['2ndJobDurationTo']}</p>
                                <p><span className="form-field-label">Address:</span>  {candidateDetailsForApplication['2ndJobCompanyAddress']}</p>

                            </div>
                        </div>

                        <hr />
                    </>
                }


                {
                    candidateDetailsForApplication['3rdJobName'] &&
                    <>
                        <div className="candidates-job-experience-details">
                            <div>
                                <p><span className="form-field-label"> Last job:</span>  {candidateDetailsForApplication['3rdJobName']}</p>
                                <p><span className="form-field-label">From:</span>  {candidateDetailsForApplication['3rdJobDurationFrom']}</p>
                                <p><span className="form-field-label">Salary:</span>  {candidateDetailsForApplication['3rdJobSalary']}</p>
                                <p><span className="form-field-label">Reason For Leaving:</span>  {candidateDetailsForApplication['3rdJobLeavingReason']}</p>

                            </div>
                            <div>
                                <p><span className="form-field-label">Company Name:</span>  {candidateDetailsForApplication['3rdJobCompanyName']}</p>
                                <p><span className="form-field-label">To:</span>  {candidateDetailsForApplication['3rdJobDurationTo']}</p>
                                <p><span className="form-field-label">Address:</span>  {candidateDetailsForApplication['3rdJobCompanyAddress']}</p>
                            </div>
                        </div>
                    </>

                }


            </div>

            <br />

            <div className="candidates-awareness-knowledge-wrappper">

                <h2 className="form-category-heading">Awareness in Security Field</h2>

                <div className="candidates-awareness-knowledge flex">
                    <div>
                        <p><span className="form-field-label">Security awareness: </span> {candidateDetailsForApplication.securityAwareness} </p>
                        <p><span className="form-field-label">First aid awareness: </span> {candidateDetailsForApplication.firstAidAwareness} </p>
                        <p><span className="form-field-label">Fire fighting awareness: </span> {candidateDetailsForApplication.fireFightingAwareness} </p>
                        <p><span className="form-field-label">Bomb threat awareness: </span> {candidateDetailsForApplication.bombThreatAwareness} </p>
                        <p><span className="form-field-label">Customer service awareness: </span> {candidateDetailsForApplication.customerServiceAwareness} </p>
                        <p><span className="form-field-label">Other security threats: </span> {candidateDetailsForApplication.otherSecurityThreats} </p>
                    </div>

                    <div>
                        <p><span className="form-field-label">Emergency response awareness: </span> {candidateDetailsForApplication.emergencyResponseAwareness} </p>
                        <p><span className="form-field-label">Crowd control awareness: </span> {candidateDetailsForApplication.crowdControlAwareness} </p>
                        <p><span className="form-field-label">Fire drill / evacuation awareness: </span> {candidateDetailsForApplication.fireDrillAwareness} </p>

                        <p><span className="form-field-label">Flood / lost & found awareness: </span> {candidateDetailsForApplication.floodLostFoundAwareness} </p>
                        <p><span className="form-field-label">Local cultural awareness: </span> {candidateDetailsForApplication.localCulturalAwareness} </p>
                        <p><span className="form-field-label">Report writing knowledge: </span> {candidateDetailsForApplication.reportWritingKnowledge} </p>

                    </div>
                </div>

            </div>

            <br />


            <div className="candidates-vaccination-details-wrappper">

                <h2 className="form-category-heading">COVID 19 Vaccination Details</h2>

                <div>
                    <p><span className="form-field-label">Certificate Available: </span> {candidateDetailsForApplication.certificateAvailable} </p>
                </div>

                <hr />

                <div>
                    <h3 style={{ marginBottom: '0px' }}>1<sup>st</sup> Dose</h3>

                    <div className="flex">
                        <div>
                            <p><span className="form-field-label">Vaccine Name: </span> {candidateDetailsForApplication['1stVaccineName']} </p>
                        </div>
                        <div>
                            <p><span className="form-field-label">Vaccination Date: </span> {candidateDetailsForApplication['1stVaccineDate']} </p>
                        </div>

                    </div>
                </div>

                <hr />

                <div>
                    <h3 style={{ marginBottom: '0px' }}>2<sup>nd</sup> Dose</h3>

                    <div className="flex">
                        <div>
                            <p><span className="form-field-label">Vaccine Name: </span> {candidateDetailsForApplication['2ndVaccineName']} </p>
                        </div>
                        <div>
                            <p><span className="form-field-label">Vaccination Date: </span> {candidateDetailsForApplication['2ndVaccineDate']} </p>
                        </div>
                    </div>
                </div>

                <hr />

                {candidateDetailsForApplication['3rdVaccineName'] &&
                    <>
                        <div>
                            <h3 style={{ marginBottom: '0px' }}>3<sup>rd</sup> Dose</h3>

                            <div className="flex">
                                <div>
                                    <p><span className="form-field-label">Vaccine Name: </span> {candidateDetailsForApplication['3rdVaccineName']} </p>
                                </div>
                                <div>
                                    <p><span className="form-field-label">Vaccination Date: </span> {candidateDetailsForApplication['3rdVaccineDate']} </p>
                                </div>

                            </div>
                        </div>
                    </>
                }

            </div>


            <br />

            <div className="candidates-additional-info">

                <h2 className="form-category-heading">Additional Details</h2>


                <p><span className="form-field-label">Have you previously been employees by SSS?: </span> {candidateDetailsForApplication.oldEmployee} </p>
                <p><span className="form-field-label">Do you have any relative, Friend currenty working in SSS?: </span> {candidateDetailsForApplication.anyoneWorkingInSSS} </p>
                <p><span className="form-field-label">How did you know about this company?: </span> {candidateDetailsForApplication.companyInfoSource} </p>

            </div>

            <div style={{ marginTop: '170px' }}>
                <h2 className="form-category-heading">Interviewer’s comments</h2>
                <p>Selected / Rejected</p>
                <p> Selected for :</p>
                <p> Grade :</p>
                <p>Name :</p>
                <p>Signature :</p>
            </div>

        </div>
    );
});

