import { useContext } from "react";
import { Link } from "react-router-dom";
import { userDetailsContext } from "../../context/userDetailsContext";


const CandidateList = ({ renderCandidateList, updateCandidateStatus, deleteCandidateDetails }) => {

    const { userData } = useContext(userDetailsContext);

    const nationalities = ['Nepal', 'India', 'Pakistan', 'Bangladesh', 'Philippines', 'Sri Lanka']


    return (
        <div>
            {
                renderCandidateList.map((candidate, index) => {
                    return (
                        <div key={index} className={`${index % 2 === 0 ? "candidate-bg" : ""} candidate `}>

                            <div>
                                <h4 className={`${candidate.isSelected ? "selected" : "rejected"}`}>
                                    {candidate.name + " "}
                                    <span style={{ color: "black", fontSize: '14px', fontWeight: 'normal' }}
                                    >
                                        {" "}({candidate.nationality} | {candidate.passportNo})
                                    </span>
                                </h4>
                            </div>

                            <div className={`candidate-recruitment-btns ${userData.role.toLowerCase() === 'admin' ? 'add-width' : ''}`} >

                                {/* Select and reject buttons for super admin and the interviewer */}

                                {
                                    (userData.role.toLowerCase() === 'admin' || userData.role.toLowerCase() === 'interviewer') && (
                                        <>
                                            <button onClick={() => updateCandidateStatus(index, 'select')}>Select</button>
                                            <button style={{ margin: '0 20px' }} onClick={() => updateCandidateStatus(index, 'reject')}>Reject</button>
                                        </>
                                    )
                                }

                                {/* Delete Candidate Button for super admin only */}

                                {
                                    userData.role.toLowerCase() === 'admin' && (
                                        <button onClick={() => deleteCandidateDetails(index)}>Delete</button>
                                    )
                                }

                                {/*  View Details Button*/}
                                <Link to={candidate.docID}>
                                    <button className={`${userData.role.toLowerCase() !== 'interviewer' ? 'add-margin' : ''} `}>View Details</button>
                                </Link>

                                {/* Onboarding Projess */}

                                {
                                    (candidate.isSelected === true && userData.role.toLowerCase() !== 'interviewer') && (

                                        nationalities.includes(candidate.nationality) ?
                                            <Link to={`${candidate.docID}/qvc-booking`}>
                                                <button>
                                                    Onboarding Process
                                                </button>
                                            </Link>
                                            :
                                            <Link to={`${candidate.docID}/visa-issue`}>
                                                <button>
                                                    Onboarding Process
                                                </button>
                                            </Link>
                                    )
                                }

                            </div>


                        </div>
                    )
                })
            }
        </div >
    )
}

export default CandidateList;


