import { useEffect, useState } from "react";
import { GetUserListFromDatabase, updateUserRoleInDatabase } from "../../Utilities/firebase/firebase.utils"
import HeaderDashboard from "../dashboard-header-component/headerDashboard.component"
import SideBar from "../dashboard-sidebar-component/sidebar.component"

export const AllUserList = () => {


    const [userList, setUserList] = useState([]);
    const [filteredUserList, setFilteredUserList] = useState([])
    const [searchField, setSearchField] = useState()
    const [getUpdatedData, setGetUpdatedData] = useState(false);


    useEffect(() => {

        const getUserList = (users) => {
            setUserList(users)
            setFilteredUserList(users)
        }

        GetUserListFromDatabase(getUserList);
    }, [getUpdatedData])


    useEffect(() => {

        const filteredUsers = userList.filter((user) => {
            return user.displayName.toLowerCase().startsWith(searchField);
        })

        setFilteredUserList([...filteredUsers])

    }, [searchField])

    const onSearchHandeler = (event) => {
        const searchFieldString = event.target.value.toLowerCase();
        setSearchField(searchFieldString);
    }

    const handleUpdateUserRole = (docID) => {
        updateUserRoleInDatabase(docID);
        alert("Sucessfully promoted to admin");

        setGetUpdatedData(!getUpdatedData)
    }


    return (
        <>
            <SideBar />

            <div className="dashboard-center">

                <HeaderDashboard onSearchHandeler={onSearchHandeler} placeholder='Search user...' />

                <div className="user-list dasboard-candidate-list">

                    <h2>Candidates</h2>
                    <div className="border" style={{ marginBottom: '20px' }} />


                    {filteredUserList.map((user, index) => {
                        return (
                            <div key={index} className={`${index % 2 === 0 ? "candidate-bg" : ""} candidate `}>
                                <div>
                                    <h4>
                                        {user.displayName} <span style={{ fontSize: '16px', fontWeight: 'normal' }}>({user.role})</span>
                                    </h4>
                                </div>

                                <div>
                                    <button onClick={() => handleUpdateUserRole(user.docID)}>Make Admin</button>
                                </div>

                            </div>
                        )
                    })}

                </div>
            </div>
        </>
    )
}